import React from 'react'
import { Wrapper, SEO } from '@renderbus/common/components'
import Layout from '../molecules/layout'
import {
  XgtnzhdPoster,
  XgtnzhdContent,
  RechargeButton,
  PosterTextImg,
  Date,
  SectionImg,
  Section,
  DetailBlock,
  StepBlock,
  RuleBlock,
  Tag,
  Amount,
  NowDiscount,
  OldDiscount,
  DiscountContainer,
  ActivityDetailFooter,
  Step,
  StepContainer,
  LabelContainer,
  Calculation,
  BlockFooter,
  RulesContainer,
  Rule,
  Tada,
  StepArrow,
} from './xgtnzhd.atom'

import AcitvityDetailImg from '../images/xgtnzhd/detail.png'
import ParticipateWayImg from '../images/xgtnzhd/participate-way.png'
import RulesImg from '../images/xgtnzhd/rule.png'
import Img100 from '../images/xgtnzhd/100.png'
import Img300 from '../images/xgtnzhd/300.png'
import Img500 from '../images/xgtnzhd/500.png'
import Img1000 from '../images/xgtnzhd/1000.png'
import Step1 from '../images/xgtnzhd/avatar.png'
import Step2 from '../images/xgtnzhd/center.png'
import Step3 from '../images/xgtnzhd/v-big.png'
import VIPImg from '../images/xgtnzhd/v-small.png'
import Discount5 from '../images/xgtnzhd/5-dicount.png'
import Discount4 from '../images/xgtnzhd/4-discount.png'

const DiscountList = [
  {
    img: Img100,
    now: '充100立送100',
    old: '原充100送10',
    marginLeft: '69px',
  },
  {
    img: Img300,
    now: '充300立送300',
    old: '原充300送50',
  },
  {
    img: Img500,
    now: '充500立送500',
    old: '原充500送100',
    marginLeft: '69px',
  },
  {
    img: Img1000,
    now: '充1000立送1000附赠1个月会员',
    old: '原充1000送300',
    top: '30px',
    topMb: '13%',
    marginLeftMb: '6.93vw',
  },
]

const StepList = [
  {
    img: Step1,
    label: ['登录瑞云效果图', '云渲染账户'],
    paddingTop: '44px',
  },
  {
    img: Step2,
    label: ['充值中心', '活动页充值'],
    paddingTop: '34px',
  },
  {
    img: Step3,
    label: ['自动赠送', '渲染券/会员'],
    activated: true,
    paddingTop: '38px',
  },
]

class Xgtnzhd extends React.PureComponent {
  render() {
    const {
      location,
      pageContext: { topThreeShare },
    } = this.props
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='效果图年终渲染大回馈活动 - Renderbus瑞云渲染'
          keywords='效果图充值活动,效果图渲染活动,瑞云渲染充值活动'
          description='Renderbus瑞云渲染效果图年终渲染大回馈活动来袭,充值立返100%！满一千再送会员享5折渲染和免单权益,快来立即充值吧~'
        />
        <XgtnzhdContent>
          <XgtnzhdPoster>
            <PosterTextImg />
            <RechargeButton
              href='https://account.renderbus.com/center/user/topUp?activeTab=3'
              id='xgtnzhd-ljcz'
            >
              立即充值
            </RechargeButton>
            <Date>活动时间：2022年11月1日～12月31日</Date>
          </XgtnzhdPoster>
          <Wrapper>
            <Section marginBottomMb='15vw'>
              <SectionImg src={AcitvityDetailImg} alt='活动详情' />
              <DetailBlock>
                {DiscountList.map(item => (
                  <Tag key={item.now}>
                    <Amount
                      marginLeft={item.marginLeft}
                      marginLeftMb={item.marginLeftMb}
                      src={item.img}
                    />
                    <DiscountContainer top={item.top} topMb={item.topMb}>
                      <NowDiscount>{item.now}</NowDiscount>
                      <OldDiscount>{item.old}</OldDiscount>
                    </DiscountContainer>
                  </Tag>
                ))}
                <ActivityDetailFooter />
              </DetailBlock>
            </Section>
            <Section>
              <Tada left />
              <SectionImg src={ParticipateWayImg} alt='参与方式' />
              <StepBlock>
                <StepContainer>
                  {StepList.map((item, index) => (
                    <Step
                      activated={item.activated}
                      paddingTop={item.paddingTop}
                      key={item.label[0]}
                    >
                      <img src={item.img} alt='' />
                      <LabelContainer>
                        {item.label.map(v => (
                          <p key={v}>{v}</p>
                        ))}
                      </LabelContainer>
                      {index !== StepList.length - 1 && <StepArrow nth={index} />}
                    </Step>
                  ))}
                </StepContainer>
                <Calculation>
                  <img src={VIPImg} alt='' />
                  效果图会员 = <img src={Discount5} alt='' />
                  全场五折渲染 + <img src={Discount4} alt='' />
                  4张不限额免单券
                </Calculation>
                <BlockFooter left />
                <BlockFooter right />
              </StepBlock>
            </Section>
            <Section marginTop='107px'>
              <Tada />
              <SectionImg src={RulesImg} alt='活动规则' />
              <RuleBlock>
                <RulesContainer>
                  <Rule>
                    用户参与活动完成充值后，赠送的会员/渲染券直接到账，若30分钟未到账，请咨询效果图客服；
                  </Rule>
                  <Rule>若用户原已是效果图会员，则赠送的会员时长自动累积到剩余时长中；</Rule>
                  <Rule activated>
                    本次活动限“活动充值”页有效，“现金充值”页充值不参与活动折扣；
                  </Rule>
                  <Rule>
                    活动赠送会员权益中包含的免单券不可申请发票、不可提现，此优惠不与其他充值优惠活动同享；
                  </Rule>
                  <Rule>
                    本次活动充值到账金额仅限效果图渲染使用，充值金额不限期，赠送渲染券有效期365天，且不支持退款和提现；
                  </Rule>
                  <Rule>如有任何问题，请咨询瑞云效果图客服，活动名额数量有限，先到先得！</Rule>
                  <Rule>
                    本次活动最终解释权归瑞云科技所有，瑞云科技有权对恶意刷抢活动资源、利用活动资源从事违法违规行为的用户收回活动参与资格，并对涉事账号进行封禁处理！
                  </Rule>
                </RulesContainer>
                <BlockFooter left />
                <BlockFooter right />
              </RuleBlock>
            </Section>
          </Wrapper>
        </XgtnzhdContent>
      </Layout>
    )
  }
}

export default Xgtnzhd
